<template>
  <div class="container">
    <div class="main">
      <img src="../../assets/images/login.svg" class="main-img" />
      <div class="login-form">
        <div class="logo">
          <img src="../../assets/images/logo.png" />
          <div>AI可视化工地管理系统</div>
        </div>
        <div>
          <div class="text">用手机号登录</div>
          <div class="input">
            <a-input v-model="form.attr1" placeholder="请输入手机号"></a-input>
          </div>
          <div class="input">
            <a-input
              v-model="form.attr2"
              placeholder="请输入密码"
              type="password"
            ></a-input>
          </div>
          <div class="select">
            <a-checkbox @change="onChange" :checked="rememberUser"
              >记住密码</a-checkbox
            >
          </div>
          <div class="button" @click="doLogin">
            <a-icon type="loading" v-show="loading" />
            立即登录
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="version">北京全掌握科技有限公司 京ICP备2022023461号-1</div> -->
    <img
      src="../../assets/images/login_bottom.png"
      alt=""
      class="img-bottom left"
    />
    <img
      src="../../assets/images/login_bottom_right.png"
      alt=""
      class="img-bottom right"
    />
  </div>
</template>

<script>
import md5 from "md5";
import { asyncRouterMap, asyncRouterMapjf } from "@/config/router.config.js";

export default {
  name: "Login",
  data() {
    return {
      loading: false,
      rememberUser: false,
      form: {
        attr1: "",
        attr2: "",
      },
      R_Right: [],
    };
  },
  created() {
    let storage = localStorage.getItem("INFO");
    if (storage) {
      this.rememberUser = true;
      this.form = JSON.parse(storage);
    }
  },
  // computed: {
  //   R_Right(NV, OV) {
  //     console.log(NV, OV);
  //   },
  // },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("Received values of form: ", values);
        }
      });
    },
    doLogin() {
      this.loading = true;
      let params = {
        attr1: this.form.attr1,
        type: "dsmcore",
        attr2: md5(this.form.attr2),
      };
      this.$store
        .dispatch("Login", params)
        .then(() => {
          this.loading = false;
          // 修改vuex存储的路由name，并跳转
          // this.$store.commit("SET_CURRENT", "SiteList");
          // this.$router.push({ path: "/SiteList" });
          //

          console.log("$store.state.user", this.$store.state.user);
          setTimeout(() => {
            // 如果现场管理系统菜单有值，则跳转到首页
            if (this.$store.state.user.roles != "") {
              console.log("现场管理系统菜单有值");
              this.$store.commit("SET_CURRENT", "HomeMaster");
              this.$router.push({ path: "/HomeMaster" });
              this.$store.commit("SET_SYSTEM", "xc");
              localStorage.setItem("path", "HomeMaster");
              localStorage.setItem("SET_SYSTEM", "xc");
            } else {
              console.log("asyncRouterMap现场管理系统菜单为空", asyncRouterMap);
              // 如果现场管理系统菜单为空，跳转到交付系统第一个页面
              let pageArrs = [];
              for (let i = 0; i < asyncRouterMap.length; i++) {
                let pageArr = [];

                for (let k = 0; k < asyncRouterMap[i].children.length; k++) {
                  /*   if (
                  this.R_Right.indexOf(asyncRouterMap[i].children[k].name) != -1
                )  */
                  if (
                    this.R_Right.indexOf(asyncRouterMap[i].children[k].name) ==
                    -1
                  ) {
                    pageArr.push(asyncRouterMap[i].children[k]);
                  }
                }
                if (pageArr.length != 0) {
                  let pageObject = {};
                  pageObject.path = asyncRouterMap[i].path;
                  pageObject.name = asyncRouterMap[i].name;
                  pageObject.meta = asyncRouterMap[i].meta;
                  pageObject.component = asyncRouterMap[i].component;
                  pageObject.redirect = asyncRouterMap[i].redirect;
                  pageObject.children = pageArr;
                  pageArrs.push(pageObject);
                }
              }
              // this.$store.commit("SET_CURRENT", pageArrs[0].children[0].name);
              // this.$router.push({
              //   path: pageArrs[0].children[0].path,
              // });
              // this.$store.commit("SET_SYSTEM", "jf");
              // localStorage.setItem("path", pageArrs[0].children[0].name);
              // localStorage.setItem("SET_SYSTEM", "jf");
              this.$store.commit("SET_CURRENT", "MaterialCategories");
              this.$router.push({
                path: "/MaterialCategories",
              });
              this.$store.commit("SET_SYSTEM", "jf");
              localStorage.setItem("path", "MaterialCategories");
              localStorage.setItem("SET_SYSTEM", "jf");
            }
          }, 200);
          // this.$store.commit("SET_CURRENT", "DailyMonitor");
          // this.$router.push({ path: "/DailyMonitor" });
          // setTimeout(() => {
          //   // console.log(this.$store.state.user.roles.split(","));
          //   this.R_Right = this.$store.state.user.roles.split(",");
          //   // console.log("this.R_Right", this.R_Right);

          //   if (this.R_Right.includes("SiteList")) {
          //     this.$store.commit("SET_CURRENT", "SiteList");
          //     this.$router.push({ path: "/SiteList" });
          //   } else if (
          //     !this.R_Right.includes("SiteList") &&
          //     this.R_Right.includes("DailyMonitor")
          //   ) {
          //     this.$store.commit("SET_CURRENT", "DailyMonitor");
          //     this.$router.push({ path: "/DailyMonitor" });
          //   }
          // }, 30);
          // // this.remenber();
        })
        .catch((res) => {
          this.loading = false;
          // this.$message.warn("登录失败");
          this.$message.warn(res.data);
        });
    },
    remenber() {
      if (this.rememberUser) {
        localStorage.setItem(
          "INFO",
          JSON.stringify({
            attr1: this.form.attr1,
            attr2: md5(this.form.attr2),
          })
        );
      } else {
        localStorage.setItem("INFO", "");
      }
    },
    onChange(event) {
      this.rememberUser = event.target.checked;
    },
  },
};
</script>

<style>
.container {
  height: inherit;
  background: linear-gradient(77.15deg, #0349fa 2.23%, #0395f5 96.73%);
}
.main {
  position: relative;
  top: 100px;
  width: 800px;
  margin: 0 auto;
  border-radius: 20px;
  box-shadow: 0px 2px 45px rgba(11, 32, 90, 0.2);
  display: flex;
  z-index: 1;
}
.main-img {
  width: 400px;
  border-radius: 20px 0 0 20px;
}
.login-form {
  width: 400px;
  padding: 40px;
  background: #fff;
  border-radius: 0 20px 20px 0;
}
.logo {
  height: 90px;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 36px;
  color: #34333b;
  text-align: center;
}
.text {
  text-align: left;
  font-size: 16px;
  margin-bottom: 30px;
}
.input {
  margin-bottom: 24px;
}
.input .ant-input {
  height: 46px;
}
.select {
  text-align: left;
  margin-bottom: 16px;
}
.button {
  background: linear-gradient(259.32deg, #0349fa 7.93%, #0395f5 92.07%);
  border-radius: 2px;
  width: 100%;
  color: #fff;
  height: 46px;
  line-height: 46px;
}
.button:hover {
  cursor: pointer;
  background: linear-gradient(259.32deg, #0349fa 7.93%, #0349fa 92.07%);
}
.img-bottom {
  position: absolute;
  bottom: 0;
  width: 75%;
}
.left {
  left: 0;
}
.right {
  right: 0;
}
.version {
  position: absolute;
  z-index: 999;
  bottom: 20px;
  width: 100%;
  color: #fff;
}
</style>
